import { useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";

import {
    Autocomplete,
    Box,
    Card,
    CardActions,
    CardContent,
    Typography,
} from "@mui/material";
import TextField from "../../../../../components/TextField";
import Button from "../../../../../components/Button";

import { useStyles } from "./styles";
import { usePopup } from "../../../../../hooks/usePopup";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { IOnboardingClientWithClient } from "../../../../../interfaces/AbacateApi/IOnboardingClient";

import { AxiosError, AxiosResponse } from "axios";
import {
    AbacateApiErros,
    AbacateGetResponse,
} from "../../../../../utils/Api/responses/responses";
import clientsService from "../../../../../services/clientsApiService";
import ComponentHeader from "../../../../../components/ComponentHeader";
import useTeams from "../../../../../hooks/SellersApi/useTeams";
import useFrexcoSellers from "../../../../../hooks/SellersApi/useFrexcoSellers";

let searchFormSchema = yup.object().shape({
    parameter_type: yup.string().required("Tipo do parâmetro é obrigatório."),
    parameter_value: yup
        .string()
        .required("O valor a pesquisar é obrigatório."),
});

export const OnboardingClientEditForm = () => {
    const classes = useStyles();
    const { addPopup } = usePopup();

    const [onboardingClient, setOnboardingClient] =
        useState<IOnboardingClientWithClient>();
    const [gettingOnboardingClient, setGettingOnboardingClient] =
        useState(false);

    const [editingFarmer, setEditingFarmer] = useState(false);

    const {
        register: registerSearch,
        handleSubmit: handleSubmitSearch,
        formState: { errors: searchErrors },
        reset: resetSearch,
    } = useForm({ resolver: yupResolver(searchFormSchema) });

    const handleSearch = async (searchParameters: FieldValues) => {
        let parameter_type = searchParameters.parameter_type.toLowerCase();
        let params = {
            [parameter_type]: searchParameters.parameter_value,
        };

        getOnboardingClient(params);
        resetSearch();
    };

    const getOnboardingClient = async (params: Object) => {
        setGettingOnboardingClient(true);
        await clientsService
            .getOnboardingClient(params)
            .then(
                (
                    resp: AxiosResponse<
                        AbacateGetResponse<IOnboardingClientWithClient>
                    >
                ) => {
                    setOnboardingClient(resp.data.results[0]);
                }
            )
            .catch((err: AxiosError<AbacateApiErros>) => {
                addPopup({
                    title: "Erro ao buscar cliente",
                    description: err.response?.data.error,
                    type: "error",
                    duration: "temporary",
                });
            })
            .finally(() => {
                setGettingOnboardingClient(false);
            });
    };

    const { data: teamsResponse } = useTeams({
        active: true,
        role__in: JSON.stringify(["Farm"]),
    });

    const { data: farmersResponse, isFetching: gettingFarmersResponse } =
        useFrexcoSellers(
            {
                active: true,
                squads__overlap: teamsResponse?.results
                    ? JSON.stringify(
                          teamsResponse?.results.map((team) => team.name)
                      )
                    : undefined,
            },
            {
                enabled: !!teamsResponse?.results,
            }
        );

    const {
        handleSubmit: handleSubmitEdit,
        setValue: setEditValue,
        control: editFormControl,
    } = useForm();

    const handleEditOnboardingClient = async (data: FieldValues) => {
        await patchOnboardingClient(onboardingClient!.id!, {
            farmer: data.farmer.name,
        });
    };

    const patchOnboardingClient = async (
        id: number,
        data: { farmer: string }
    ) => {
        setEditingFarmer(true);
        await clientsService
            .patchOnboardingClient(id, data)
            .then(() => {
                addPopup({
                    title: "Registro alterado com sucesso.",
                    type: "success",
                    duration: "temporary",
                });
            })
            .catch((err: AxiosError<AbacateApiErros>) => {
                addPopup({
                    title: "Erro ao atualizar registro.",
                    description: err.response?.data.error,
                    type: "error",
                    duration: "temporary",
                });
            })
            .finally(() => {
                setEditingFarmer(false);
            });
    };

    return (
        <Box>
            <ComponentHeader.Root>
                <ComponentHeader.Title text="Editar responsável de clientes" />
            </ComponentHeader.Root>
            <Box className={classes.container}>
                <form
                    onSubmit={handleSubmitSearch(handleSearch)}
                    className={classes.form}
                >
                    <Autocomplete
                        sx={{ width: "220px" }}
                        options={[
                            { id: 1, label: "CPNJ" },
                            { id: 2, label: "Name" },
                        ]}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                            option.label === value.label
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Pesquisar por"
                                placeholder="Parâmetro"
                                error={!!searchErrors?.parameter_type}
                                helperText={
                                    searchErrors?.parameter_type?.message ? (
                                        <>
                                            {
                                                searchErrors?.parameter_type
                                                    ?.message
                                            }
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }
                                {...registerSearch("parameter_type", {
                                    required: true,
                                })}
                            />
                        )}
                    />
                    <TextField
                        variant="outlined"
                        label="Parâmetro"
                        error={!!searchErrors?.parameter_value}
                        helperText={
                            searchErrors?.parameter_value?.message ? (
                                <>{searchErrors?.parameter_value?.message}</>
                            ) : (
                                <></>
                            )
                        }
                        {...registerSearch("parameter_value", {
                            required: true,
                        })}
                    />
                    <Button loading={gettingOnboardingClient} type="submit">
                        <Typography variant="body2">Pesquisar</Typography>
                    </Button>
                </form>
                {onboardingClient && (
                    <Box className={classes.displayBox}>
                        <Card variant="outlined" className={classes.card}>
                            <CardContent>
                                <Typography variant="subtitle2">
                                    {onboardingClient.client_infos.name}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    color="secondary.light"
                                >
                                    Cnpj:{" "}
                                    {onboardingClient.client_infos.cpf_cnpj}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    color="secondary.light"
                                >
                                    Telefone 1:{" "}
                                    {
                                        onboardingClient.client_infos
                                            .phone_number_1
                                    }
                                </Typography>
                                <Typography
                                    variant="h4"
                                    color="secondary.light"
                                >
                                    Telefone 2:{" "}
                                    {
                                        onboardingClient.client_infos
                                            .phone_number_2
                                    }
                                </Typography>
                                <CardActions>
                                    <form
                                        onSubmit={handleSubmitEdit(
                                            handleEditOnboardingClient
                                        )}
                                    >
                                        <Controller
                                            name="farmer"
                                            control={editFormControl}
                                            defaultValue={{
                                                name: onboardingClient.farmer,
                                            }}
                                            render={({
                                                field: { ref, ...field },
                                            }) => (
                                                <Autocomplete
                                                    {...field}
                                                    loading={
                                                        gettingFarmersResponse
                                                    }
                                                    sx={{ width: "220px" }}
                                                    options={
                                                        farmersResponse?.results ||
                                                        []
                                                    }
                                                    getOptionLabel={(option) =>
                                                        option.name
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) =>
                                                        option.name ===
                                                        value.name
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            inputRef={ref}
                                                            name="farmer"
                                                            variant="outlined"
                                                            label="Selecionar farmer"
                                                            placeholder="Farmer"
                                                        />
                                                    )}
                                                    onChange={(e, value) => {
                                                        setEditValue(
                                                            "farmer",
                                                            value
                                                        );
                                                    }}
                                                />
                                            )}
                                        />

                                        <Button
                                            type="submit"
                                            loading={editingFarmer}
                                        >
                                            <Typography variant="body2">
                                                Atualizar
                                            </Typography>
                                        </Button>
                                    </form>
                                </CardActions>
                            </CardContent>
                        </Card>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
