import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import DataGrid from "../../../../../components/DataGrid";
import ComponentHeader from "../../../../../components/ComponentHeader";

import { IDistributionSummary } from "../../../../../interfaces/SdrApi/IDistributionSummary";

import useDistributionSummary from "../../../../../hooks/SdrApi/useDistribution";

import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";

import {
    getLast7Days,
    todayMinusXAsString,
} from "../../../../../utils/dateFunctions";

import BlockSellersDialog from "../BlockSellersDialog";

const DistributionSummaryGrid = () => {
    const [openBlockSellersDialog, setOpenBlockSellersDialog] = useState(false);

    const {
        data: distributionSummary,
        isFetching: gettingDistribtuionSummary,
    } = useDistributionSummary(
        {
            created__gte: todayMinusXAsString(7),
            origin__in: JSON.stringify([
                "Activation",
                "Records Without Orders",
            ]),
            summary: true,
        },
        {
            select: (data: IDistributionSummary[]) =>
                data.filter((record) => record.seller_name !== "Automação"),
        }
    );

    const [columnDefs, setcolumnDefs] = useState<
        GridColDef<IDistributionSummary>[]
    >([
        {
            field: "seller_name",
            headerName: "Nome",
            minWidth: 200,
            flex: 1,
        },
    ]);

    useEffect(() => {
        if (!!distributionSummary?.length) {
            const dates = getLast7Days();
            const datesColumnDefs: GridColDef<IDistributionSummary>[] =
                dates.map((date) => ({
                    field: date,
                    headerName: date.replace("_", "/"),
                    flex: 1,
                    valueGetter: (value) => {
                        if (!value.value) {
                            return 0;
                        }

                        return value.value;
                    },
                }));
            setcolumnDefs((state) => [...state, ...datesColumnDefs]);
        }
    }, [distributionSummary]);

    return (
        <>
            <Box>
                <ComponentHeader.Root>
                    <ComponentHeader.Title text="Leads distribuídos" />
                    <ComponentHeader.Actions>
                        <ComponentHeader.Action
                            tooltip="Vendedores bloqueados"
                            icon={
                                <PlaylistRemoveIcon
                                    fontSize="large"
                                    color="error"
                                />
                            }
                            action={() => setOpenBlockSellersDialog(true)}
                        />
                    </ComponentHeader.Actions>
                    <ComponentHeader.Desc text="Nessa tabela você pode visualizar como foi a distribuição dos leads pros melhores vendedores nos últimos 7 dias" />
                </ComponentHeader.Root>
                <Box width={"100%"} height={500}>
                    <DataGrid<IDistributionSummary>
                        rows={distributionSummary ? distributionSummary : []}
                        columns={columnDefs}
                        loading={gettingDistribtuionSummary}
                        getRowId={(row) => row.seller_name}
                    />
                </Box>
            </Box>
            {openBlockSellersDialog && (
                <BlockSellersDialog
                    openDialog={openBlockSellersDialog}
                    setOpenDialog={setOpenBlockSellersDialog}
                />
            )}
        </>
    );
};

export default DistributionSummaryGrid;
