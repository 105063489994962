import { ChangeEvent, useState } from "react";

import {
    Box,
    Dialog,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Tooltip,
} from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";

import DataGrid from "../../../../../components/DataGrid";
import ComponentHeader from "../../../../../components/ComponentHeader";

import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";

import useFrexcoSellers from "../../../../../hooks/SellersApi/useFrexcoSellers";
import useMutation from "../../../../../hooks/useMutate";

import FrexcoSellersApi from "../../../../../services/SellersApi/frexcoSellersApi";

const frexcoSellersApi = new FrexcoSellersApi();

interface IBlockSellersDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const BlockSellersDialog = ({
    openDialog,
    setOpenDialog,
}: IBlockSellersDialog) => {
    const handleClose = () => {
        setOpenDialog(false);
    };

    const [isBlocked, setIsBlocked] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsBlocked(JSON.parse((event.target as HTMLInputElement).value));
    };

    const {
        data: frexcoSellersResponse,
        isFetching: gettingFrexcoSellersResponse,
    } = useFrexcoSellers({
        active: true,
        inbound_punishment: isBlocked,
    });

    const { mutate: mutateFrexcoSeller, isLoading: mutatingFrexcoSeller } =
        useMutation({
            successText: "Vendedor alterado.",
            errorText: "Falha ao alterar vendedor.",
            queryKeyArray: ["frexco-sellers", "hunters-sales"],
            callback: frexcoSellersApi.put,
        });

    const columns: GridColDef<IFrexcoSeller>[] = [
        {
            field: "name",
            headerName: "Nome",
            flex: 2,
        },
        {
            field: "actions",
            type: "actions",
            getActions: (params) => {
                return isBlocked
                    ? [
                          <GridActionsCellItem
                              icon={
                                  <Tooltip title="Desbloquear">
                                      <SettingsBackupRestoreOutlinedIcon color="primary" />
                                  </Tooltip>
                              }
                              label="Desbloquear"
                              onClick={() =>
                                  mutateFrexcoSeller({
                                      id: parseInt(params.row.id!),
                                      object: {
                                          ...params.row,
                                          inbound_punishment: false,
                                      },
                                  })
                              }
                          />,
                      ]
                    : [
                          <GridActionsCellItem
                              icon={
                                  <Tooltip title="Bloquear">
                                      <BlockOutlinedIcon color="error" />
                                  </Tooltip>
                              }
                              label="Bloquear"
                              onClick={() =>
                                  mutateFrexcoSeller({
                                      id: parseInt(params.row.id!),
                                      object: {
                                          ...params.row,
                                          inbound_punishment: true,
                                      },
                                  })
                              }
                          />,
                      ];
            },
        },
    ];

    return (
        <Dialog open={openDialog} onClose={handleClose} maxWidth={"sm"}>
            <Box margin={"10px"}>
                <ComponentHeader.Root>
                    <ComponentHeader.Title text="Bloqueados" />
                    <ComponentHeader.Desc text="Nessa tabela você pode bloquear/desbloquear vendedores de receberem leads através do bot de distribuição." />
                </ComponentHeader.Root>
                <FormControl>
                    <RadioGroup
                        defaultValue="false"
                        onChange={handleChange}
                        row
                    >
                        <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Não bloqueados"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Bloqueados"
                        />
                    </RadioGroup>
                </FormControl>
                <Box width={"100%"} height={400}>
                    <DataGrid
                        columns={columns}
                        rows={
                            frexcoSellersResponse?.results
                                ? frexcoSellersResponse?.results
                                : []
                        }
                        loading={
                            gettingFrexcoSellersResponse || mutatingFrexcoSeller
                        }
                    />
                </Box>
            </Box>
        </Dialog>
    );
};

export default BlockSellersDialog;
