import { useCallback, useEffect, useState } from "react";
import { usePopup } from "../../../../../hooks/usePopup";
import { useStyles } from "./styles";

import { Box, Skeleton } from "@mui/material";

import {
    SdrBasedErrors,
    SdrBasedResponses,
} from "../../../../../utils/Api/responses/responses";
import { AxiosError, AxiosResponse } from "axios";

import sellersApiService from "../../../../../services/SellersApi/sellersApiService";

import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";
import AccordionItem from "./AccordionItem";
import useClientsWithoutFarmerParamsService from "../../../../../hooks/SdrApi/useClientsWithoutFarmerParams";
import ComponentHeader from "../../../../../components/ComponentHeader";
import useTeams from "../../../../../hooks/SellersApi/useTeams";

const FarmerAttributionList = () => {
    const { addPopup } = usePopup();
    const classes = useStyles();

    const [sellers, setSellers] = useState<IFrexcoSeller[]>([]);

    const { data: clientsWithoutFarmerParams } =
        useClientsWithoutFarmerParamsService({ per_page: 100 });

    const { data: teamsResponse, isFetching: gettingTeamResponse } = useTeams({
        active: true,
        role__in: JSON.stringify(["Hunter"]),
    });

    const getSellers = useCallback(async () => {
        await sellersApiService
            .getFrexcoSellers({
                active: true,
            })
            .then(async (resp: AxiosResponse<SdrBasedResponses>) => {
                const count = resp.data.info.count;
                await sellersApiService
                    .getFrexcoSellers({
                        active: true,
                        per_page: count,
                    })
                    .then((resp: AxiosResponse<SdrBasedResponses>) => {
                        const sellers = resp.data.results;
                        setSellers(sellers);
                    });
            })
            .catch((err: AxiosError<SdrBasedErrors>) => {
                addPopup({
                    title: "Falha ao buscar farmers.",
                    description: err.response?.data.error,
                    type: "error",
                    duration: "temporary",
                });
            });
    }, [addPopup]);

    const isOnboardingTeam = (sellerTeams: string[]) => {
        const onboardingTeams = new Set(["Farmers", "Onboarding"]);
        return sellerTeams.some((element) => onboardingTeams.has(element));
    };

    useEffect(() => {
        if (teamsResponse) {
            getSellers();
        }
    }, [getSellers, teamsResponse]);

    return (
        <>
            <Box className={classes.container}>
                <ComponentHeader.Root>
                    <ComponentHeader.Title text="Atribuição automática de ganhos a farmers" />
                </ComponentHeader.Root>
                {!gettingTeamResponse ? (
                    teamsResponse?.results.map((team) => (
                        <AccordionItem
                            key={team.id}
                            team={team}
                            farmers={sellers.filter((seller) =>
                                isOnboardingTeam(seller.squads)
                            )}
                            hunters={sellers.filter((seller) =>
                                seller.squads.includes(team.name)
                            )}
                            huntersParams={
                                clientsWithoutFarmerParams
                                    ? clientsWithoutFarmerParams.results
                                    : []
                            }
                        />
                    ))
                ) : (
                    <>
                        <Skeleton sx={{ height: "80px" }} />
                        <Skeleton sx={{ height: "80px" }} />
                        <Skeleton sx={{ height: "80px" }} />
                        <Skeleton sx={{ height: "80px" }} />
                        <Skeleton sx={{ height: "80px" }} />
                    </>
                )}
            </Box>
        </>
    );
};

export default FarmerAttributionList;
